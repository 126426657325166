import React, { useEffect } from 'react'
import failed from '../../images/payment-failed.svg'
import { useLocation } from "react-router-dom"

function paymentsuccess () {
    // const search = useLocation()?.search
    // const message = new URLSearchParams(search)?.get('message')

    return (
        <>
       <div className='payment-wrapper'>
       <div className='payment-success'>
        <img src={failed}/>
        <h4 className='failed'>Payment Failed!</h4>
         <h6>Contact us:</h6>
         <a href='/'>admin@taggrsolutions.com</a>
      
         </div>
       </div>
        </>
    )
}


export default paymentsuccess

