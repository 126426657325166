import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import brandLogo from "../../images/brand-logo.svg";
import "./styles.scss"

const Header = () => {


    return (
        <>
            <header className="HeaderMain">
                <Link >
                    <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                    <h6 className="BrandTagline">Enforcement Simplified </h6>
                </Link>

            </header>
        </>
    );
}
export default Header

