import React from 'react';
import './App.css';
import Wrapper from './Wrapper';


function App() {
  return (
    <div>
      <Wrapper />
    </div>
  );
}

export default App;
