import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom"
import success from '../../images/payment-success.svg'

export default function Success() {
  const search = useLocation()?.search
  const message = new URLSearchParams(search)?.get('message')
  const urlSearchParams = new URLSearchParams(window.location.search);
  const dropBoxDescription = new URLSearchParams(search).get('Location')

  console.log(dropBoxDescription,"1111111111111111");
  
  console.log(message, "<<message")
  const code = message?.substring(message.lastIndexOf(' ') + 1);

  const paymentBy = urlSearchParams.get('payment_by');
  const navigate_to_dashboard = () => {
    console.log('test');
    const dashboardURL =
      process.env.REACT_APP_HOST === 'DEVELOPMENT'
        ? 'http://localhost:3001/admincitations'
        : 'https://dashboard.taggrsolutions.com/admincitations';
    window.location.href = dashboardURL;
  };
  console.log(paymentBy,"------------->>>>>");

  return (
    <>
      <div className='payment-wrapper'>
        <div className='payment-success'>
          <img src={success} />
          <h4 className='success'>Payment Successful</h4>
          {/* <p className='errorMsg'>{`The Release code of the Tire Tag is`}</p> */}
          {/* <p>The Release code of the tire tag is </p> */}
          <p>The release code for the Tire Tag is </p>
          <div className='greenBtn'>{code?.slice(0, -1)}</div>
          <div className='Bordered'>
            <h3 className='locationHeading'>Return the undamaged tag to the Drop box located here</h3>
            <p className='locationPara'>{dropBoxDescription}</p>
            <h5 className='LocationAvoid'>to avoid a $75 unreturned tag fee</h5>
           </div>
          {paymentBy && (
            <div>
              <button className='ReturnBtn' onClick={navigate_to_dashboard}>Return to Dashboard</button>
            </div>
           )}
        </div>
        
      </div>
    </>
  )
}
