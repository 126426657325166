import React, { Suspense, lazy, useEffect } from 'react'
import { Routes, Route } from "react-router-dom";

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Header from '../component/common/Header'
import PaymentSuccess from "../utils/paymentsucess/Paymentsuccess"
import Paymenfailed from '../utils/paymentfailed/paymentfailed'

import "./App.scss";
const CitationsSummary = lazy(() => import("../pages/CitationsSummary/CitationsSummary"));
const LookUp = lazy(() => import("../pages/LookUp/Lookup"));
const CitationsPayment = lazy(() => import("../pages/CitationsPayment/CitationsPayment"));
const Citations = lazy(() => import("../pages/Citations/Citations"));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function Wrapper() {
    useEffect(() => {
        window.scrollTo(0, 0);
        //eslint-disable-next-line
    }, []);

    return (
        <>
            <Header />
            <Routes>
                {/* <Route
                    path="/citations"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Citations />
                        </Suspense>
                    }
                /> */}
                <Route
                    path="/citations-payment"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Elements stripe={stripePromise}>
                                <CitationsPayment />
                            </Elements>
                        </Suspense>
                    }
                />               

                <Route
                    path="/citations-summary"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <CitationsSummary />
                        </Suspense>
                    }
                />
                <Route
                    path="/"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <LookUp />
                        </Suspense>
                    }
                />
                <Route
                    path="paymentsuccess"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <PaymentSuccess />
                        </Suspense>
                    }
                />
                <Route
                    path="paymentfailed"
                    element={
                        <Suspense fallback={<p>Loading...</p>}>
                            <Paymenfailed />
                        </Suspense>
                    }
                />
            </Routes>
        </>
    )
}

export default Wrapper
